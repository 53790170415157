.szoveg {
  padding: 40px 15px 50px;

  h3 {
    font-size: 22px;
    @media (max-width: $screen-xs-max) {
      font-size: 19px;
    }
    font-weight: bold;
    font-style: italic;
    color: #666666;
    margin-bottom: 30px;
  }

  h4, h5, h6 {
    margin-bottom: 30px;
    color: $szurke;
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
    font-size: 16px;
    color: $text-color;
    font-family: 'DINPro-light';
  }

  strong, b {
    color: #555;
  }

  ul, ol {
    list-style-position: inside;
    padding-left: 0;
    li {
      font-weight: bold;
    }
  }

  img {
    max-width: 100%;
    @include img-responsive();
  }

  figcaption {
    color: $szurke;
    font-size: 14px;
  }

  a {
    color: $brand-primary;
    text-decoration: none;
    &:hover {
      color: $szurke;
    }
  }

  table {
    width: 100%;
    border: 0;
    thead {
      > tr {
        background-color: $brand-primary;
        border: 1px solid #777;
        color: $brand-warning;
        > th {
          text-align: center;
          border: 1px solid #777;
          vertical-align: middle;
          padding: 5px 0;
        }
      }
    }

    tbody {
      > tr {
        border: 0px solid transparent;
        > td {
          border: 1px solid $brand-primary;
          padding: 2px 0;
          vertical-align: middle;
          color: $brand-primary;
          text-align: center;
        }
      }
      > tr:nth-child(odd) {
        background-color: #fff;
      }

      > tr:nth-child(even) {
        background-color: #ededed;
      }
    }
  }
}