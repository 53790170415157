body{

}

.container-fluid{
  background: #fff;
  margin: 0 50px;
  padding: 0;
  @media (max-width: $screen-sm-max) {
    margin: 0 10px;
  }
}

.felso-sav{
  overflow: hidden;
  background: white;
  height: 300px;
  position: relative;
  @media (max-width: $screen-md-max) {
    height: 200px;
  }

  @media (max-width: $screen-sm-max) {
    height: 15vh;
  }

 /* @media (max-width: $screen-xs-max) {
    font-size: 30px;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    text-align: center;
  }*/


  h1{
    margin: 0;
    font-weight: bold;
    color: $brand-warning;
    font-size: 100px;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translate(0,-50%);

    @media (max-width: $screen-md-max) {
      font-size: 70px;
    }

    @media (max-width: $screen-sm-max) {
      font-size: 50px;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      text-align: center;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 30px;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      text-align: center;
    }
  }
  img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.btn-primary{
  border-radius: 0;
  color: $brand-warning;
  border-color: $brand-primary;
  text-transform: uppercase;
  padding: 5px 13px 6px;
  //font-family: 'DINPro-light';
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

.bg-transparent{
  background: transparent;
}

.bg-gray{
  background: #666666;
}

.vonas{
  &:before {
    content: "";
    border-top: 4px solid $brand-warning;
    width: 90px;
    display: block;
    margin-bottom: 15px;
  }
}

h1,h2,h3,h4,h5,h6{
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'DINPro-bold';
}

a{
  text-decoration: none;
  &:hover,&:focus{
    text-decoration: none;
  }
}

