.szolgaltatas-lista {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
  }

  > .szolgaltatas-lista-elem {
    width: 25%;
    max-width: 25%;
    padding: 35px 30px;
    min-height: 400px;
    background: $brand-primary;

    @media (max-width: $screen-sm-max) {
      width: 50%;
      max-width: 50%;
      min-height: 300px;
    }

    @media (max-width: $screen-xs-max) {
      width: 100%;
      max-width: 100%;
    }

    &:nth-child(2) {
      background: darken($brand-primary, 5%);
    }
    &:nth-child(4) {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        background: darken($brand-primary, 7%);
      }
    }

    &:nth-child(3) {
      background: $brand-warning;
      h2, .lead {
        color: $text-color;
      }
    }

    h2 {
      margin-top: 0;
      color: $brand-primary;
      text-transform: uppercase;
      color: white;
      @media (max-width: $screen-sm-max) {
        font-size: 22px;
      }
    }

    .lead {
      font-size: 16px;
      color: white;
      @media (max-width: $screen-sm-max) {
        font-size: 14px;
      }
    }

    .btn-primary {
      padding: 4px 12px;
      font-size: 12px;
    }
  }
}