#fancybox-thumbs {
  position: fixed;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 8050;
}

#fancybox-thumbs.bottom {
  bottom: 2px;
}

#fancybox-thumbs.top {
  top: 2px;
}

#fancybox-thumbs ul {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

#fancybox-thumbs ul li {
  float: left;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
  padding: 0;
  opacity: 0.5;
}

#fancybox-thumbs ul li.active {
  opacity: 0.75;
  padding: 0;
}

#fancybox-thumbs ul li:hover {
  opacity: 1;
}

#fancybox-thumbs ul li a {
  display: block;
  position: relative;
  overflow: hidden;
  background: #111;
  outline: none;
}

#fancybox-thumbs ul li img {
  display: block;
  position: relative;
  border: 0;
  padding: 0;
  max-width: none;
}