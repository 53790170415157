@font-face {
  font-family: 'DINPro-black';
  src: url('/fonts/DINPRO-BLACK.OTF') format('opentype'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'DINPro-bold';
  src: url('/fonts/DINPRO-BOLD.OTF') format('opentype'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'DINPro-light';
  src: url('/fonts/DINPro-Light.woff') format('woff'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'DINPro-medium';
  src: url('/fonts/DINPro-Medium.woff') format('woff'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'DINPro';
  src: url('/fonts/DINPro.woff') format('woff'), /* Pretty Modern Browsers */
}
