header {
  .navbar {
    background: $body-bg;
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
    .navbar-brand {
      position: relative;
      padding: 0;
      width: 172px;
      background-color: white;
      margin-right: 32px;


      @media (max-width: $screen-xs-max) {
        max-width: 45vw;
        margin: 0;
      }

      &:hover {
        background-color: white;
      }
      > a {
        display: block;
        > img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 45vw;
          max-height: 100px;
          @media (min-width: $screen-md-min) {
            max-height: 72px;
          }
        }
      }
    }

    .navbar-nav {
      @media (min-width: $screen-md-min) {
        max-height: 100px;
      }
      overflow: hidden;
      li {
        a {
          padding: 40px 18px;
          text-transform: uppercase;
          color: #5e5e5e;
          font-size: 18px;
          font-family: DINPro;

          @media (max-width: $screen-sm-max) {
            padding: 15px 8px;
            text-align: center;
            font-size: 20px;
          }

          &:hover,&:focus,&.active{
            color: $brand-primary;
          }
        }
      }

      #kereses-form {
        position: relative;
        padding-right: 35px;
        input {
          background: transparent;
          border: 0;
          transition: none;
          box-shadow: none;
          border-radius: 0;
          border-bottom: 1px solid #6b6b6b;

        }
        ::-webkit-input-placeholder {
          text-transform: uppercase;
        }

        ::-moz-placeholder { /* Firefox 19+ */
          text-transform: uppercase;
        }

        :-ms-input-placeholder {
          text-transform: uppercase;
        }
        span {
          color: $brand-primary;
          font-size: 30px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}