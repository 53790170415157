@import "_bootstrap.scss";
@import "_fonts.scss";
@import "defaults";
@import "header";
@import "footer";
@import "fooldal";
@import "kapcsolat";
@import "szolgaltatas";
@import "referencia";
@import "hir";
@import "szoveg";
@import "_fancybox.scss";
@import "_fancybox_thumbs.scss";

#map {
  width: 100%;
  max-height: 446px;
  height: 50vh;
}

.kereses-lista.szoveg {
  background: white;
  h4 {
    color: $brand-primary;
  }
  .kereses-elem {
    margin-bottom: 10px;

    a {
      font-size: 18px;
      font-weight: bold;
      &:hover, &:focus {
        color: darken($brand-primary, 20%);
      }
    }
  }

}