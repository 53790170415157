footer {
  &.container-fluid {
    background: transparent;
  }

  .bg-transparent {
    padding-top: 25px;
    min-height: 86px;
  }

  .bg-gray {
    padding-top: 50px;
    padding-bottom: 65px;
  }

  .row {
    > div {
      padding: 0 50px;
    }
  }

  a {
    color: $brand-warning;
    &:hover, &:focus {
      color: white;
      text-decoration: none;
    }
  }

  .hir {
    position: relative;
    padding-right: 0 !important;
    margin-right: 55px;

    .btn-primary {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0px 8px;

      @media (max-width: $screen-xs-max) {
        margin: 10px 0px;
        position: relative;
      }
    }

    h4 {
      margin-top: 0;
      margin-bottom: 30px;
      text-transform: uppercase;
      color: white;
      font-size: 30px;
      font-family: 'DINPro-light';
      font-weight: normal;

    }

    h5 {
      color: white;
      font-size: 15px;
      text-transform: uppercase;
      font-family: 'DINPro-bold';
      letter-spacing: 2px;
      @media (max-width: $screen-xs-max) {
        margin-top: 10px;
      }
    }

    img{
      @media (max-width: $screen-xs-max) {
        float: none !important;
        margin: 5px auto;
      }
    }

    p {
      color: #bababa;
      font-size: 15px;
      line-height: 1.5;
      font-weight: 300;
    }

    > a {
      text-transform: uppercase;
    }
  }

  .kapcsolat {
    color: #bababa;
    @media (max-width: $screen-xs-max) {
      margin-top: 70px;
    }
    h4 {
      text-transform: uppercase;
      color: white;
      margin-top: 0;
      font-size: 30px;
      margin-bottom: 30px;
      font-family: 'DINPro-light';
      font-weight: normal;
    }
    h5 {
      color: white;
      letter-spacing: 2px;
    }

    p {
      margin-bottom: 3px;
      font-weight: 300;
    }
  }
}