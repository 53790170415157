#hir {
  .hir-lista {
    margin: 0;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: flex-start;

    .hir-lista-elem {
      min-height: 300px;
      padding: 30px;
      flex-grow: 1;
      width: 25%;
      max-width: 25%;
      position: relative;

      a {
        &:hover, &:focus {
          h2 {
            color: $brand-warning;
          }
        }
      }

      @media (max-width: $screen-sm-max) {
        width: 50%;
        max-width: 50%;
      }
      @media (max-width: $screen-xs-max) {
        width: 100%;
        max-width: 100%;
      }

      .also-vonal {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        height: 1px;
        width: calc(100% - 60px);
        border-bottom: 1px solid $szurke;
      }

      .oldalso-vonal {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 1px;
        height: calc(100% - 60px);
        border-right: 1px solid $szurke;
      }

      h2 {
        margin-top: 0;
        color: $brand-primary;
        font-size: 30px;
      }

      .lead {
        font-size: 15px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        color: $text-color;
      }

      .btn-primary {
        font-size: 12px;
        padding: 4px 13px 5px;
      }


    }

    .hir-tobb-resz {
      .hir-tobb {
        background: $brand-primary;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        border: none;
        color: $brand-warning;
        font-size: 24px;
        border-radius: 0;

        &:hover,&:focus{
          color: white;
        }

        p{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

#hir-model {
  .container {
    padding-top: 80px;
    padding-bottom: 120px;

    h2 {
      margin-top: 0;
      font-family: 'DINPro-medium';
      color: $brand-primary;
      text-transform: uppercase;
      margin-bottom: 40px;
    }

    .lead {
      font-family: 'DINPro-medium';
      font-size: 24px;
      line-height: 1.3;
      color: $text-color;
    }

    .hir-kep {
      float: left;
      margin-right: 50px;
      margin-bottom: 50px;
      @media (max-width: $screen-xs-max) {
        float: none;
        display: block;
      }
    }
  }
}