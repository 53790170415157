.munkafolyamatok {
  display: flex;
  align-items: stretch;

  .munkafolyamat-lista {
    width: 25%;
    display: flex;
    align-content: stretch;
    align-items: stretch;
    flex-direction: column;
    position: relative;

    .munkafolyamat-elem {
      width: 100%;
      background: $brand-primary;
      height: 25%;
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      flex-grow: 1;
      cursor: pointer;

      &:hover:not(.active), &:focus:not(.active) {
        cursor: pointer;
        .munkafolyamat-cim {
          color: $brand-warning;
          text-shadow: 1px 1px 1px black;
          cursor: pointer;
        }

        .layer {
          background: rgba(30, 30, 30, 0.3);
        }
      }

      .layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(30, 30, 30, 0.55);
        z-index: 10;
      }

      .munkafolyamat-cim {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //position: relative;
        //margin-left: auto;
        //margin-right: auto;
        z-index: 12;
        margin: 0;
        color: white;
        font-size: 15px;
        text-align: center;
        line-height: 1.8;
        cursor: pointer;
        width: 98%;

        @media (max-width: $screen-sm-max) {
          line-height: 1.4;
          font-family: DINPro;
          font-size: 12px;
          width: 100%;
        }

        span {
          color: $brand-warning;
        }
      }

      &.active {
        background: white !important;

        .layer {
          opacity: 0;
        }

        h2 {
          color: $brand-primary;
          span {
            color: $text-color;
          }
        }
      }
    }
  }
  .munkafolyamat-kep {
    width: 75%;
    position: relative;
    //border-left: 1px solid #ededed;
  }

  .kep-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    @include transition(all 0.7s ease-in-out);
    z-index: 10;
    cursor: pointer;

    &:before {
      content: "";
      width: 1px;
      background: #ededed;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }

    &:after {
      content: "";
      width: 1px;
      background: #ededed;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }

    &.kep-layer-1 {
      width: 25%;
      left: 0;
      background-image: url('/images/mf1.jpg');
      background-position: 0 0;
    }
    &.kep-layer-2 {
      width: 25%;
      left: 25%;
      background-image: url('/images/mf2.jpg');
      background-position: calc(100% * 1 / 3) 0;
    }
    &.kep-layer-3 {
      width: 25%;
      left: 50%;
      background-image: url('/images/mf3.jpg');
      background-position: calc(100% * 2 / 3) 0;
    }
    &.kep-layer-4 {
      width: 25%;
      left: 75%;
      background-image: url('/images/mf4.jpg');
      background-position: right 0 top 0;
    }

    &.open {
      z-index: 12;
      width: 100%;
      left: 0;
      .kep-szoveg {
        opacity: 1;
      }
    }
    &.opening {
      z-index: 12;
      .kep-szoveg {
        transition-delay: 0s;
      }
    }

    .kep-szoveg {
      color: white;
      font-size: 16px;
      font-weight: bold;
      opacity: 0;
      @include transition(opacity 0.3s ease-in-out);
      transition-delay: 0.6s;
      position: absolute;

      //p {
        @media (max-width: 1400px) {
          font-size: 14px;
        }

        @media (max-width: $screen-sm-max) {
          font-size: 13px;
        }
      //}

      &.kep-szoveg-bal {
        top: 15px;
        left: 20px;
        max-width: 400px;
      }

      &.kep-szoveg-jobb {
        bottom: 15px;
        right: 20px;
        max-width: 400px;
      }
    }

  }

}