#kapcsolat {
  .telepules-lista {
    margin: 0;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: flex-start;

    .telepules-oszlop {
      flex-grow: 1;
      width: 25%;
      max-width: 25%;
      position: relative;
      display: flex;
      flex-direction: column;

      @media (max-width: $screen-sm-max) {
        width: 50%;
        max-width: 50%;
      }
      @media (max-width: $screen-xs-max) {
        width: 100%;
        max-width: 100%;
      }

      .telepules-elem {
        width: 100%;
        min-height: 300px;
        padding: 30px;
        position: relative;

        &.telepules {
          .telepules-megnevezes {
            font-family: 'DINPro-light';
            font-size: 18px;
            margin-bottom: 0;
            text-transform: uppercase;
          }

          .telepules-nev {
            color: $brand-primary;
            font-size: 30px;
            margin-top: 8px;
            margin-bottom: 25px;
          }

          .telepules-lead {
            font-weight: 300;
            font-size: 15px;
          }
        }

        &.munkatars {
          .munkatars-beosztas {
            font-family: 'DINPro-light';
            font-size: 18px;
            margin-bottom: 0;
            text-transform: uppercase;
            color: $text-color;
          }

          .munkatars-nev {
            font-family: 'DINPro-light';
            color: $brand-primary;
            font-size: 30px;
            margin-top: 8px;
            margin-bottom: 25px;
            font-weight: normal;
          }

          .munkatars-mobil, .munkatars-telefon, .munkatars-email {
            color: $text-color;
            font-weight: 300;
            font-size: 15px;
            margin-bottom: 5px;

            p {
              width: 55px;
              display: inline-block;
              margin-right: 20px;
              margin-bottom: 0;
            }
          }
        }
        &.map {
          position: relative;
          .telepules-cim {
            position: absolute;
            top: 10px;
            left: 9px;
            padding: 13px 18px 10px;
            background: white;
            font-family: 'DINPro-light';
            font-size: 18px;
            text-transform: uppercase;
          }
        }
      }

    }

    .also-vonal {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      height: 1px;
      width: calc(100% - 60px);
      border-bottom: 1px solid $szurke;
    }

    .oldalso-vonal {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: 1px;
      height: calc(100% - 60px);
      border-right: 1px solid $szurke;
    }

    /*.telepules-elem {
      min-height: 300px;
      padding: 30px;
      flex-grow: 1;
      width: 25%;
      max-width: 25%;
      position: relative;

      @media (max-width: $screen-sm-max) {
        width: 50%;
        max-width: 50%;
      }
      @media (max-width: $screen-xs-max) {
        width: 100%;
        max-width: 100%;
      }

      .also-vonal {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        height: 1px;
        width: calc(100% - 60px);
        border-bottom: 1px solid $szurke;
      }

      .oldalso-vonal {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 1px;
        height: calc(100% - 60px);
        border-right: 1px solid $szurke;
      }

      h2 {
        margin-top: 0;
        color: $brand-primary;
        font-size: 30px;
      }

      .lead {
        font-size: 15px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
      }
    }*/
  }

  #kapcsolatmodal {
    .modal-content {
      padding: 80px 100px 70px;
      position: relative;

      @media (max-width: $screen-sm-max) {
        padding: 40px 15px 50px;
      }

      .close {
        position: absolute;
        top: 50px;
        right: 50px;
        margin: 0;
      }

      input, textarea {
        border-radius: 0;
        background: #dadada;
        color: $brand-primary;
        font-family: DINPro;
        font-size: 24px;

        @media (max-width: $screen-sm-max) {
          font-size: 22px;
        }
      }

      label {
        font-size: 16px;
        font-family: DINPro-medium;
        text-transform: uppercase;
      }

      button {
        margin-top: 30px;
      }
    }

    .modal-title {
      color: $brand-primary;
      font-family: DINPro-medium;
      font-size: 30px;
    }

    .modal-header {
      padding-left: 0;
      padding-right: 0;
      border: none;
    }

    .beljebb {
      margin: 0;
    }

    .modal-body {
      padding-left: 0;
      padding-right: 0;

      .row {
        margin: 0;
      }
    }
  }
  #kapcsolat-modal-kep {
    margin-top: 29px;
    margin-left: 15px;
  }

  #kapcsolat-modal-nev {
    height: 55px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 30px;
    background: rgba(128,128,128,0.77);
    text-align: center;
    padding-top: 20px;
    font-size: 18px;
    font-family: DINPro-light;
    color: white;
    text-transform: uppercase;
  }
}