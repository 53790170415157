#referencia {
  .referencia-lista {
    margin: 0;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    > .referencia-kep {
      padding: 0;
      position: relative;
      z-index: 9;
      width: 25%;
      max-width: 25%;

      @media (max-width: $screen-sm-max) {
        width: 50%;
        max-width: 50%;
      }
      @media (max-width: $screen-xs-max) {
        width: 100%;
        max-width: 100%;
      }
      a {
        > img {
          animation: scaleout 0.3s ease-in;
          z-index: 9;
        }

        .hover {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #7a7a7a;
          opacity: 0.8;
          animation: scaleout 0.3s ease-in;
        }

        > .tartalom {
          position: absolute;
          top: 30px;
          left: 30px;
          color: white;
          padding-right: 40px;

          > .kategoria > span {
            background: $brand-warning;
            color: $brand-primary;
            padding: 2px 10px;
            text-transform: uppercase;
            font-size: 12px;
            font-family: 'DINPro-light';
          }

          > h2 {
            text-transform: uppercase;
            font-family: 'DINPro-bold';
            font-size: 30px;
          }

          .lead {
            font-size: 15px;
            font-family: 'DINPro-light';
          }

          .btn-primary {
            padding: 0px 12px;
          }
        }
      }

      &:hover {
        z-index: 10;
        img {
          animation: scalein 0.3s ease-in;
          z-index: 10;
          @include scale(1.06);
        }

        .tartalom {
          display: none;
        }

        .hover {
          opacity: 0;
          animation: scalein 0.3s ease-in;
          @include scale(1.06);
        }
      }

      .referencia-tobb {
        background: $brand-primary;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        border: none;
        color: $brand-warning;
        font-size: 24px;
        border-radius: 0;

        &:hover,&:focus{
          color: white;
        }

        p{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

#referencia-model {
  .container {
    padding-top: 80px;
    padding-bottom: 120px;
    > h2 {
      margin-top: 0;
      font-family: 'DINPro-medium';
      color: $brand-primary;
      text-transform: uppercase;
      margin-bottom: 40px;
    }

    .lead {
      font-family: 'DINPro-medium';
      font-size: 24px;
      line-height: 1.3;
    }
    .referencia-kep {
      float: left;
      margin-right: 50px;
      margin-bottom: 50px;
      position: relative;
      > .nagyito {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

//------------------------animációk----------------------------
@keyframes scalein {
  0% {
    @include scale(1);
  }
  1% {
    z-index: 10;
    @include scale(1.01);
  }
  25% {
    z-index: 10;
    @include scale(1.02);
  }
  50% {
    z-index: 10;
    @include scale(1.035);
  }
  75% {
    z-index: 10;
    @include scale(1.045);
  }
  100% {
    z-index: 10;
    @include scale(1.06);
  }
}

@keyframes scaleout {
  0% {
    @include scale(1.06);
  }
  1% {
    @include scale(1.045);
  }
  25% {
    @include scale(1.035);
  }
  50% {
    @include scale(1.02);
  }
  75% {
    @include scale(1.01);
  }
  100% {
    @include scale(1);
  }
}

